import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG } from "../components/functions"
import Breadcrumbs from "../components/common/breadcrumbs/breadcrumbs"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import BlockBooking from "../components/common/blockBooking/blockBooking"
import GenericComponents from "../components/generic/genericComponents/genericComponents"

export default function Dine({ data: { nodeDine }, pageContext }) {
  const isSSR = typeof window === "undefined"

  const BlkIntroWide = React.lazy(() => import("../components/common/blockIntroWide/blockIntroWide"))

  const {
    title,
    field_seo,
    relationships: {
        dine_introduction: pageIntro,
        dine_carousel: carousel,
        dine_components: components,
      },
  } = nodeDine

  const intl = useIntl()

  const carouselMea = carousel
    ? carousel.relationships.carousel_highlight_item
    : null

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const componentsFiltered = components.filter(
    value => Object.keys(value).length !== 0
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="pageDine">
       <Seo data={seoData} pageContext={pageContext} />
      {carouselFiltered.length > 0 && (
        <MainBannerImage items={carouselFiltered} />
      )}
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          <Breadcrumbs
            hotelTaxonomyDrupalId={pageContext.hotelTaxonomyDrupalId}
            pageTitle={title}
          />
          <BlkIntroWide content={pageIntro}/>
          <GenericComponents components={componentsFiltered} />
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeDine(id: { eq: $id }) {
      ...dineFields
    }
  }
`